<template>
  <div class="MainLayout-Wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "MainLayout",
  components: {},
};
</script>

<style>
body {
  margin: 0px;

  background: #080a0b;
}

.MainLayout-Wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}
</style>
