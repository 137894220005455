<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: "Russo One";
  src: url(../public/fonts/RussoOne-Regular.ttf);
}

@font-face {
  font-family: "Source Code Pro";
  src: url(../public/fonts/SourceCodePro-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Metrophobic";
  src: url(../public/fonts/Metrophobic-Regular.ttf);
}

#app {
  font-family: "Russo One", "Source Code Pro", "Metrophobic", Avenir, Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
